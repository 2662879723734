import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"info"} />
		<Helmet>
			<title>
				Hakkında  | Ozel Cankurtaran Sürüş Okulu
			</title>
			<meta name={"description"} content={"Kendine Güvenen Sürüş Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:title"} content={"Hakkında  | Ozel Cankurtaran Sürüş Okulu"} />
			<meta property={"og:description"} content={"Kendine Güvenen Sürüş Yolculuğunuz Burada Başlıyor"} />
			<meta property={"og:image"} content={"https://crystallineoasis.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
			<meta name={"msapplication-TileImage"} content={"https://crystallineoasis.com/img/f_tf-20231211.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					md-text-align="left"
				>
					Hakkımızda
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Ozel Cankurtaran Sürüş Okulu olarak, müşterilerimizin farklı ihtiyaçlarını karşılamak üzere tasarlanmış kapsamlı bir hizmet paketi sunmaktan gurur duyuyoruz. Acemi sürücülerden becerilerini geliştirmek isteyenlere kadar, programlarımız en yüksek kalitede eğitim ve destek almanızı sağlamak için uyarlanmıştır.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://crystallineoasis.com/img/3.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://crystallineoasis.com/img/4.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Hizmetlerimize Giriş
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Her öğrencinin kendine özgü ihtiyaçları ve hedefleri olduğunu biliyoruz. Bu nedenle tüm deneyim seviyelerine hitap edecek çeşitli hizmetler sunuyoruz. Misyonumuz, sadece sürüş testini geçmenin ötesine geçen çok yönlü bir öğrenme deneyimi sağlamaktır. Sizi her durumda güvenli sürüş için gereken beceri ve güvenle donatmayı hedefliyoruz.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Sertifikalı Profesyoneller
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Sertifikalı Profesyoneller
					<br />
					Eğitmenlerimiz yüksek nitelikli ve sertifikalı olup deneyimli profesyonellerden birinci sınıf eğitim almanızı sağlar. Bireysel öğrenme ihtiyaçlarınıza hitap eden kişiselleştirilmiş eğitim sağlamak üzere eğitilmişlerdir.
					<br />
					<br />
Kişiselleştirilmiş Dersler
					<br />
Her ders, beceri seviyenize ve öğrenme hızınıza göre uyarlanır. İster yeni başlayın ister ileri düzey eğitime ihtiyaç duyun, eğitmenlerimiz sabır ve uzmanlıkla her adımda size rehberlik edecektir.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://crystallineoasis.com/img/5.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://crystallineoasis.com/img/6.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Eğitim Programlarımız
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Acemi Sürücü Kursları
					<br/>
Yeni başlayanlar için acemi sürücü kurslarımız, sağlam bir temel oluşturmak için gereken tüm temel bilgileri kapsar.
Temel Manevralar
					<br/>
     Direksiyon, hızlanma ve frenleme gibi temel sürüş becerilerini öğrenin.
					<br/>
     Dönüş, park etme ve şerit değiştirme gibi temel manevralarda ustalaşın.
					<br/>
					<br/>
Trafik Kuralları ve Yol İşaretleri
					<br/>
     Yol kurallarını ve trafik yasalarını anlayın.
					<br/>
     Yaygın yol işaretlerini ve anlamlarını öğrenin.

				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});